import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const YardCleanup = () => {
	const TheTitle = "Yard Cleanup Services"
	const TheDescription = "Every year the leaves fall, rain and wind knock down branches, dirt builds up on your patios, your gardens get washed out, bark mulch finds its way all over your property. Every season brings new challenges as your property seems to try to reorganize itself. It can be a big effort to sort out! Let us take care of it for you. Once or twice a year we recommend to do a big clean up and tidy up your gardens, yard, walk ways, and paved areas."
	const TheCanonical = "https://gardenescape.ca/services/yard-cleanup/"

	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			<div className="serviceGrid">
				<StaticImage
					className="serviceGrid_boxA"
					src="../../images/home/leaves.png"
					alt="background placeholder"
					layout="fullWidth" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>
			<div className="mainWrapper my-16">
			<h2>THINGS PILING UP?</h2>
			<p>Every year the leaves fall, rain and wind knock down branches, dirt builds up on your patios, your gardens get washed out, bark mulch finds its way all over your property. Every season brings new challenges as your property seems to try to reorganize itself. It can be a big effort to sort out! Let us take care of it for you. Once or twice a year we recommend to do a big clean up and tidy up your gardens, yard, walk ways, and paved areas.</p>
			<h2>WHEN SHOULD I SCHEDULE A CLEANUP?</h2>
			<p>In the fall we recommend dethatching or aeration and spreading fertilizer and lime to control moss and strengthen your grass for going dormant through the winter. Come spring your lawn will be beautiful and more robust! Fall is also a great time to prune small trees, shrubs and bushes to foster a healthy bloom in the spring/summer months.</p>
			<p>The spring clean up tradition can be daunting enough inside the house, with a rough winter a yard can need much attention. We’d be happy to come over and help! Clearing fallen debris from trees and bushes like leaves, branches and pinecones. Cleaning up dead grass and preparing flower beds. Brining in bark mulch or other yard cover and even power washing your hard surfaces.</p>
			<h2>HOW DO I PROGRESS?</h2>
			<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan that’ll best fit your yard goals and budget. Just contact us through our website, phone or email.</p>
			<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
			<p>We are happy to help you with all things related to beautifying your property, some other major services we offer are; Regular lawn mow, fertilizing, Hedge and Shrub pruning, Aeration and Junk Removal.</p>
			</div>
		</Layout>
	)
}

export default YardCleanup